.caret {
  height: 32px;
  margin: 0 8px;
  fill: var(--colors-text-body-0);
  align-self: center;
}

.headerContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0;
}

.accordionContent {
  position: relative;
}

.timeOptionsContainer {
  margin-top: 12px;
  overflow-y: auto;
  max-height: 250px;
  padding: 0 4px 15px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }
}

.timeOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 2px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 0.5px solid var(--colors-borders-secondary);
  }
}

.pickerTime {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.noTimeOptions {
  padding: 20px 40px;
  text-align: center;
}

.timeOptionSelectable {
  border-style: solid;
  border-width: 1px;
  border-color: var(--colors-text-body-2);

  &.selected {
    border-color: var(--colors-borders-tertiary);
    background-color: var(--colors-borders-tertiary);

    > div {
      border: none;
    }

    svg {
      height: 16px;
      width: 16px;
      fill: white;
    }
  }
}
